// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function HomePage() {
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch data from the API
        axios.get('https://comedytestapp-46f0cb301988.herokuapp.com/api/home')
            .then((response) => {
                setMessage(response.data.message);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            <h2>Home Page</h2>
            <p>{message}</p>
        </div>
    );
}

export default HomePage;
