// src/pages/AccountPage.js
import React from 'react';

function AccountPage() {
    return (
        <div>
            <h2>Account Page</h2>
            <p>This page is protected. Only authenticated users can access it.</p>
        </div>
    );
}

export default AccountPage;
