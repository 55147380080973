// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import AccountPage from './pages/AccountPage';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={darkTheme}>
            <Router>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" noWrap>
                            Test project
                        </Typography>
                        <Link to="/" style={{ color: 'inherit', textDecoration: 'none', marginLeft: 'auto' }}>
                            Home
                        </Link>
                        {  localStorage.getItem('authToken') ? (
                            <Link to="/account" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '16px' }}>
                                Account
                            </Link>
                        ) : (
                            <Link to="/login" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '16px' }}>
                                Login
                            </Link>
                        ) }
                    </Toolbar>
                </AppBar>
                <Container component="main" className={classes.content}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        {/* Protected Route */}
                        <Route
                            path="/account"
                            element={
                                localStorage.getItem('authenticated') ? (
                                    <AccountPage />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                    </Routes>
                </Container>
            </Router>
        </ThemeProvider>
    );
}

export default App;
