// src/pages/LoginPage.js
import React, { useState } from 'react';
import axios from 'axios';
function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        // Implement login logic here
        console.log(`Logging in with username: ${username} and password: ${password}`);
        axios.post('https://comedytestapp-46f0cb301988.herokuapp.com/api/login', {username, password})
            .then(function (response) {
                const authToken = response.data.token;
                console.log(response.data.message+', token: '+response.data.token)
                localStorage.setItem('authToken', authToken);
                localStorage.setItem('authenticated', true);
                this.props.history.push('/account');
            })
            .catch(function (error) {
                console.error('Error logging in:', error);
            });

        // For simplicity, we'll just set a flag in localStorage to indicate authentication
        //localStorage.setItem('authenticated', true);
    };

    return (
        <div>
            <h2>Login Page</h2>
            <form>
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <br />
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br />
                <button type="button" onClick={handleLogin}>Login</button>
            </form>
        </div>
    );
}

export default LoginPage;
